import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PlanMain from "../components/plan-main";
import CounterBlock from "../components/counter-block";

export const query = graphql`
  query PlanPageQuery {
    plan: sanityPlan {
      pageHeader {
        title
        description
      }
      contentSections {
        _key
        heading
        _rawContent
      }
      counterBlock {
        content {
          heading
          _rawContent
        }
        number
        goalNumber
        goalText
        bg
      }
    }
  }
`;

const PlanPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const plan = (data || {}).plan;

  return (
    <Layout>
      <Seo
        title={plan.pageHeader.title}
        description={plan.pageHeader.description}
      />

      {(plan.pageHeader || (plan.contentSections && plan.contentSections.length)) ? <PlanMain pageHeader={plan.pageHeader} content={plan.contentSections} /> : null}

      {plan.counterBlock && <CounterBlock {...plan.counterBlock} />}
    </Layout>
  );
};

export default PlanPage;
